import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flag, Heart, MessageSquare } from 'lucide-react';
import './ThreadCard.css';
import axios from 'axios';

const formatDate = (date) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = (`0${d.getMonth() + 1}`).slice(-2);
  const day = (`0${d.getDate()}`).slice(-2);
  const hours = (`0${d.getHours()}`).slice(-2);
  const minutes = (`0${d.getMinutes()}`).slice(-2);
  const seconds = (`0${d.getSeconds()}`).slice(-2);
  return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
};

const generateReportLink = (postTime) => {
  const formattedTime = formatDate(postTime);
  const baseUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSei_CVr8nOROS5ZzdwTumXa8BY991ez7EetHHxMAZ2affyubQ/viewform';
  const params = `?usp=pp_url&entry.2022101504=${encodeURIComponent(formattedTime)}&entry.1892230761=${encodeURIComponent('攻撃的な内容（誹謗中傷、差別的な発言など）')}`;
  return `${baseUrl}${params}`;
};

const ThreadCard = ({ thread, handleReply }) => {
  const navigate = useNavigate();
  const [likes, setLikes] = useState(thread.likes || 0);
  const [liked, setLiked] = useState(false);

  // LocalStorageから「いいね」状態を取得し、初期状態を設定
  useEffect(() => {
    const savedLikes = JSON.parse(localStorage.getItem('likedPosts')) || {};
    if (savedLikes[thread.THREAD_ID]) {
      setLiked(true);
      setLikes(savedLikes[thread.THREAD_ID]);
    }
  }, [thread.THREAD_ID]);

  const handleLike = async (e) => {
    e.stopPropagation();
    const savedLikes = JSON.parse(localStorage.getItem('likedPosts')) || {};

    if (liked) {
      // いいねを解除
      setLikes(likes - 1);
      setLiked(false);
      delete savedLikes[thread.THREAD_ID];
      await axios.post(`${process.env.REACT_APP_API_URL}/threadLikes`, { THREAD_ID: thread.THREAD_ID, liked: false });
    } else {
      // いいねを追加
      setLikes(likes + 1);
      setLiked(true);
      savedLikes[thread.THREAD_ID] = likes + 1;
      await axios.post(`${process.env.REACT_APP_API_URL}/threadLikes`, { THREAD_ID: thread.THREAD_ID, liked: true });
    }

    localStorage.setItem('likedPosts', JSON.stringify(savedLikes));
  };

  const handleReportClick = (postTime) => {
    const reportLink = generateReportLink(postTime);
    console.log(reportLink);
    window.open(reportLink, '_blank');
  };

  return (
    <div className="thread-card">
      <div className="thread-header">
        <div className="thread-info" onClick={() => navigate(`/thread/${thread.THREAD_ID}`)}>
          <img src={thread.PHOTO_NAME} alt="icon" width="50" height="50" />
          <div>
            <span className="thread-author">{thread.NICKNAME}</span>
            <span className="thread-date">{new Date(thread.CREATE_DATE).toLocaleString('ja-JP', { timeZone: 'UTC' })}</span>
          </div>
        </div>
        <Flag className="thread-report-flag" onClick={() => handleReportClick(thread.CREATE_DATE)} />
      </div>
      <div className="thread-content" onClick={() => navigate(`/thread/${thread.THREAD_ID}`)}>
        <h3 className="thread-title">{thread.TITLE}</h3>
        <p>{thread.TEXT}</p>
      </div>
      <div className="thread-actions">
        <button className={`action-button ${liked ? 'liked' : ''}`} onClick={handleLike}>
          <Heart /> いいね！ {likes}
        </button>
        <button className="action-button" onClick={() => handleReply(thread)}>
          <MessageSquare /> コメント
        </button>
      </div>
    </div>
  );
};

export default ThreadCard;