import React from 'react';
import './SearchBar.css';
import { FaSearch } from 'react-icons/fa';

const SearchBar = ({ query, setQuery, handleSearch }) => {
  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className="search-bar">
      <input
        type="text"
        id="search"
        name="search"
        value={query}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        placeholder="キーワードで検索"
      />
      <button onClick={handleSearch}>
        <FaSearch className="icon" />
        検索
      </button>
    </div>
  );
};

export default SearchBar;
