import React from 'react';
import styled from 'styled-components';

const TabContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 20px;
  position: relative;
  border-bottom: 2px solid #ddd;
`;

const Tab = styled.button`
  flex: 1;
  padding: 10px 0;
  background: none;
  border: none;
  color: ${(props) => (props.$active ? '#66B88C' : '#666')};
  font-size: 16px;
  cursor: pointer;
  position: relative;
  transition: color 0.3s;

  &:hover {
    color: #66B88C;
  }

  &::after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -2px;
    width: ${(props) => (props.$active ? '100%' : '0')};
    height: 2px;
    background-color: #66B88C;
    transition: width 0.3s;
  }

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -12px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #66B88C;
    display: ${(props) => (props.$active ? 'block' : 'none')};
  }
`;

const SortOptions = ({ selectedSort, setSelectedSort }) => {
  // 並べ替えオプションを「新着順」と「閲覧が多い順」の2つに限定
  const options = [
    { value: 'newest', label: '新着順' },
    { value: 'views', label: '閲覧が多い順' }
  ];

  return (
    <TabContainer>
      {options.map((option) => (
        <Tab
          key={option.value}
          $active={selectedSort === option.value}
          onClick={() => setSelectedSort(option.value)}
        >
          {option.label}
        </Tab>
      ))}
    </TabContainer>
  );
};

export default SortOptions;