import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Heart, MessageCircle } from 'lucide-react';
import './CardList.css';

const CardList = ({ thread }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/thread/${thread.THREAD_ID}`);
  };

  // コメント表示数や長いテキストをトリミングする関数
  const truncateText = (text, limit) => {
    if (text.length > limit) {
      return text.substring(0, limit) + '...';
    }
    return text;
  };

  return (
    <div className="card-list" onClick={handleClick}>
      <div className="card-header">
        <div className="card-info">
          <img src={thread.PHOTO_NAME} alt="icon" className="card-icon" />
          <div>
            <span className="card-author">{thread.NICKNAME || '名無し'}</span>
          </div>
        </div>
        <div className="card-header-right">
          <span className="card-category">{thread.CATEGORY}</span>
        </div>
      </div>
      <div className="card-content">
        <h3 className="card-title">{thread.TITLE}</h3>
        <p>{truncateText(thread.TEXT, 56)}</p>
      </div>
      <div className="card-actions">
        <div className="left-actions">
          <div className="action-info">
            <Heart size={18} />
            <span className="action-text">{thread.likes || 0}</span>
          </div>
          <div className="action-info">
            <MessageCircle size={18} />
            {/* comments は数値として表示 */}
            <span className="action-text">{thread.comments || 0}</span>
          </div>
        </div>
        <div className="read-more-button">続きを読む ＞</div>
      </div>
    </div>
  );
};

export default CardList;