import React, { useState, useEffect } from 'react';
import './CommentCard.css';
import { Heart, MessageSquare, Flag } from 'lucide-react';
import axios from 'axios';

const CommentCard = ({ comment, handleReply, level, threadId }) => {
  const [likes, setLikes] = useState(comment.likes || 0);
  const [liked, setLiked] = useState(false);

  useEffect(() => {
    const savedLikes = JSON.parse(localStorage.getItem('likedComments')) || {};
    if (savedLikes[comment.COMMENT_ID]) {
      setLiked(true);
      setLikes(savedLikes[comment.COMMENT_ID]);
    }
  }, [comment.COMMENT_ID]);

  const handleLike = async (e) => {
    e.preventDefault();
    const savedLikes = JSON.parse(localStorage.getItem('likedComments')) || {};

    try {
      if (liked) {
        setLikes(likes - 1);
        setLiked(false);
        delete savedLikes[comment.COMMENT_ID];
        await axios.post(`${process.env.REACT_APP_API_URL}/commentLikes`, {
          COMMENT_ID: comment.COMMENT_ID,
          THREAD_ID: threadId, // THREAD_IDを含める
          liked: false
        });
      } else {
        setLikes(likes + 1);
        setLiked(true);
        savedLikes[comment.COMMENT_ID] = likes + 1;
        await axios.post(`${process.env.REACT_APP_API_URL}/commentLikes`, {
          COMMENT_ID: comment.COMMENT_ID,
          THREAD_ID: threadId, // THREAD_IDを含める
          liked: true
        });
      }

      localStorage.setItem('likedComments', JSON.stringify(savedLikes));
    } catch (error) {
      console.error('Error handling like:', error);
    }
  };

  const handleReportClick = (postTime) => {
    const formattedTime = new Date(postTime).toLocaleString();
    const baseUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSei_CVr8nOROS5ZzdwTumXa8BY991ez7EetHHxMAZ2affyubQ/viewform';
    const params = `?usp=pp_url&entry.2022101504=${encodeURIComponent(formattedTime)}&entry.1892230761=${encodeURIComponent('攻撃的な内容（誹謗中傷、差別的な発言など）')}`;
    const reportLink = `${baseUrl}${params}`;
    window.open(reportLink, '_blank');
  };

  return (
    <div id={comment.COMMENT_ID} className="comment-card">
      <div className="comment-header">
        <div className="comment-info">
          <img src={comment.PHOTO_NAME} alt="avatar" className="comment-icon" />
          <div className="comment-meta">
            <span className="comment-author">{comment.NICKNAME}</span>
            <span className="comment-date">{new Date(comment.CREATE_DATE).toLocaleString('ja-JP', { timeZone: 'UTC' })}</span>
          </div>
        </div>
        <Flag className="comment-report-flag" onClick={() => handleReportClick(comment.CREATE_DATE)} />
      </div>
      <div className="comment-content">
        <p>{comment.TEXT}</p>
      </div>
      <div className="comment-actions">
        <button className={`action-button ${liked ? 'liked' : ''}`} onClick={handleLike}>
          <Heart /> {likes}
        </button>
        {level === 0 && (
          <button className={`action-button scroll-button-${comment.COMMENT_ID}`} onClick={() => handleReply(comment)}>
            <MessageSquare />
          </button>
        )}
      </div>
    </div>
  );
};

export default CommentCard;