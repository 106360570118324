import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import BrowseThreads from './components/BrowseThreads';
import PostThread from './components/PostThread';
import ThreadDetail from './components/ThreadDetail';
import GuideLine from './components/GuideLine';
import './App.css';

function App() {
  return (
    <Router>
      <div className="app">
        <Header />
        <main className="main-content">
          <Routes>
            <Route path="/" element={<BrowseThreads />} />
            <Route path="/browse" element={<BrowseThreads />} />
            <Route path="/new-post" element={<PostThread />} />
            <Route path="/thread/:id" element={<ThreadDetail />} />
            <Route path="/guideline" element={<GuideLine />} /> {/* Ensure path is correct */}
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;