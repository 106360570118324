import React, { useState } from 'react';
import styled from 'styled-components';
import DOMPurify from 'dompurify'; // DOMPurifyをインポート
import { Icon1, Icon2, Icon3 } from '../styles/icons';
import './CommentForm.css';

const FormWrapper = styled.form`
  background-color: #FFF9EB; /* ベースカラー背景色 */
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

const IconSelector = styled.div`
  position: relative;
  display: inline-block;
`;

const SelectedIcon = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`;

const IconDropdown = styled.div`
  display: ${(props) => (props.open ? 'block' : 'none')};
  position: absolute;
  top: 60px;
  left: 0;
  background-color: #FFF9EB; /* ベースカラー背景色 */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 10;
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
    &:hover {
      background-color: #7BC7A3; /* ホバー時のメインカラー */
    }
  }
`;

const Input = styled.input`
  width: calc(100% - 70px);
  padding: 10px 15px;
  margin-left: 15px;
  font-size: 14px;
  border: 1px solid #D1D1D1; /* 無効状態の色 */
  border-radius: 4px;
  outline: none;
`;

const Textarea = styled.textarea`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #D1D1D1; /* 無効状態の色 */
  border-radius: 4px;
  font-size: 14px;
  resize: vertical;
  min-height: 100px;
  &:focus {
    border-color: #66B88C; /* フォーカス時のメインカラー */
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Button = styled.button`
  background-color: #66B88C; /* メインカラー */
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #7BC7A3; /* ホバー時のメインカラー */
  }

  &:disabled {
    background-color: #A9A9A9;
    cursor: not-allowed;
  }
`;

const CommentForm = ({ onSubmit, onCancel, replyingTo }) => {
  const [TEXT, setTEXT] = useState('');
  const [NICKNAME, setNICKNAME] = useState('');
  const [PHOTO_NAME, setPHOTO_NAME] = useState(Icon1);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // 送信中の状態管理

  // ニックネームの変更をリアルタイムに処理し、バリデーションを行う
  const handleNicknameChange = (e) => {
    const value = e.target.value;
    setNICKNAME(value);

    // 12文字を超えた場合にエラーメッセージを表示
    if (value.length > 12) {
      e.target.setCustomValidity('ニックネームは12文字以内で入力してください');
    } else {
      e.target.setCustomValidity(''); // 12文字以内ならエラーメッセージをクリア
    }
  };

  const handleTextChange = (e) => {
    const value = e.target.value;
    setTEXT(value);

    // 入力が5文字以上になったらエラーメッセージをクリア
    if (value.length >= 5) {
      e.target.setCustomValidity('');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isSubmitting) return; // 送信中なら処理を停止
    setIsSubmitting(true); // ボタンを無効化して、連打防止

    const sanitizedText = DOMPurify.sanitize(TEXT); // TEXTをサニタイズ
    const nicknameToUse = NICKNAME.trim() === '' ? '名無し' : NICKNAME;

    // 5文字未満の場合、ポップアップでエラーメッセージを表示
    if (sanitizedText.length < 5) {
      const textArea = document.getElementById("TEXT");
      textArea.setCustomValidity("内容は5文字以上で入力してください");
      textArea.reportValidity();
      setIsSubmitting(false); // バリデーション後に再度有効化
      return; // 送信を中断
    }

    // ニックネームのバリデーション (12文字以内)
    if (NICKNAME.length > 12) {
      const nicknameInput = document.getElementById("NICKNAME");
      nicknameInput.setCustomValidity('ニックネームは12文字以内で入力してください');
      nicknameInput.reportValidity();
      setIsSubmitting(false); // バリデーション後に再度有効化
      return;
    }

    // エラーメッセージをクリア
    document.getElementById("TEXT").setCustomValidity('');
    document.getElementById("NICKNAME").setCustomValidity('');

    // 通常の送信処理
    onSubmit(sanitizedText, nicknameToUse, PHOTO_NAME);
    setTEXT('');
    setNICKNAME('');
    setIsSubmitting(false); // 送信完了後に再度ボタンを有効化
  };

  const handleIconClick = (icon) => {
    setPHOTO_NAME(icon);
    setIsDropdownOpen(false);
  };

  return (
    <FormWrapper onSubmit={handleSubmit}>
      {replyingTo && (
        <div className="replying-to">
          <p>返信先: {replyingTo.NICKNAME || '名無し'}</p>
          <button type="button" onClick={onCancel}>キャンセル</button>
        </div>
      )}
      <InputWrapper>
        <IconSelector>
          <SelectedIcon onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
            <img src={PHOTO_NAME} alt="Selected Icon" />
          </SelectedIcon>
          <IconDropdown open={isDropdownOpen}>
            <img src={Icon1} alt="Icon 1" onClick={() => handleIconClick(Icon1)} />
            <img src={Icon2} alt="Icon 2" onClick={() => handleIconClick(Icon2)} />
            <img src={Icon3} alt="Icon 3" onClick={() => handleIconClick(Icon3)} />
          </IconDropdown>
        </IconSelector>
        <Input
          type="text"
          id="NICKNAME"
          name="NICKNAME"
          value={NICKNAME}
          onChange={handleNicknameChange}
          placeholder="ニックネームを入力 (任意)"
        />
      </InputWrapper>
      <Textarea
        id="TEXT"
        name="TEXT"
        value={TEXT}
        onChange={handleTextChange}
        placeholder="コメントする"
        required
      />
      <ButtonWrapper>
        <Button type="submit" disabled={isSubmitting} className="comment-button">コメントする</Button>
      </ButtonWrapper>
      <p className="comment-note">
        コメントを投稿すると、<a href={`${window.location.origin}/guideline`} className="link-button" target="_blank" rel="noopener noreferrer">ガイドライン</a>に同意したものとみなされます。
      </p>
    </FormWrapper>
  );
};

export default CommentForm;