import React from 'react';
import styled from 'styled-components';
import '../App.css';
import { Logo } from '../styles/icons';

const HeaderWrapper = styled.header`
  background-color: var(--background-color);
  padding-top: 16px;
  padding-bottom: 0px;
  margin-bottom: 0px;
`;

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; /* テキストを中央に配置 */
`;

const LogoImage = styled.img`
  height: 64px;
  width: 64px;
  margin-right: 10px;
`;

const Brand = styled.span`
  font-size: var(--heading-medium);
  font-weight: 700;
  color: var(--text-color);
  white-space: nowrap;
  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const Header = () => (
  <HeaderWrapper>
    <Container>
      <LogoImage src={Logo} alt="Logo" />
      <Brand>介護のお悩み相談所</Brand>
    </Container>
  </HeaderWrapper>
);

export default Header;
