import React, { useState, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import '../App.css';

const CategoryFilterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
`;

const CategoryButton = styled.button`
  padding: 5px 15px;
  font-size: var(--meta-text);
  color: var(--primary-color);
  background-color: var(--background-color);
  border: 1px solid var(--primary-color);
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &.active, &:hover {
    background-color: var(--primary-color);
    color: white;
  }
`;

const CategoryFilter = ({ categories, selectedCategory, setSelectedCategory }) => {
  const [isLocked, setIsLocked] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false); // スクロール状態を管理
  const buttonsRef = useRef([]); // ボタンへの参照を保持

  // スクロール状態を監視して制御
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolling(true);
      // スクロール停止後に状態をリセット
      setTimeout(() => setIsScrolling(false), 100);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // カテゴリ選択処理
  const handleCategorySelection = useCallback((category) => {
    if (isLocked || isScrolling) return;  // ロック中やスクロール中は無視
    setIsLocked(true);
    setSelectedCategory(category);

    // 300ms後にロックを解除
    setTimeout(() => {
      setIsLocked(false);
    }, 300);
  }, [isLocked, isScrolling, setSelectedCategory]);

  // タッチイベントの制御
  const handleTouchEvent = useCallback((category, event) => {
    if (!isScrolling) {
      handleCategorySelection(category);
    }
  }, [isScrolling, handleCategorySelection]);

  useEffect(() => {
    const buttonRefs = [...buttonsRef.current];

    // 各ボタンに touchstart イベントリスナーを追加
    buttonRefs.forEach((button, index) => {
      if (button) {
        const category = categories[index];
        button.addEventListener('touchstart', (event) => handleTouchEvent(category, event));
      }
    });

    // クリーンアップ時にイベントリスナーを削除
    return () => {
      buttonRefs.forEach((button) => {
        if (button) {
          button.removeEventListener('touchstart', handleTouchEvent);
        }
      });
    };
  }, [categories, handleTouchEvent]);

  return (
    <CategoryFilterWrapper>
      {categories.map((category, index) => (
        <CategoryButton
          key={category}
          type="button"
          className={selectedCategory === category ? 'active' : ''}
          onClick={(event) => {
            event.preventDefault();
            handleCategorySelection(category);
          }}
          ref={el => (buttonsRef.current[index] = el)} // 各ボタンにrefを付与
          disabled={isLocked} // ロック中はボタンを無効化
        >
          {category}
        </CategoryButton>
      ))}
    </CategoryFilterWrapper>
  );
};

export default CategoryFilter;