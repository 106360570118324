import React, { useState } from 'react';
import axios from 'axios';
import PostForm from './PostForm';

const PostThread = () => {
  const [NICKNAME, setNICKNAME] = useState('');
  const [TITLE, setTITLE] = useState('');
  const [TEXT, setTEXT] = useState('');
  const [CATEGORY, setCATEGORY] = useState('');
  const [PHOTO_NAME, setPHOTO_NAME] = useState('');
  const [message, setMessage] = useState('');

  const addThread = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/threads`, 
        { TITLE, TEXT, CATEGORY, NICKNAME: NICKNAME || '名無し', PHOTO_NAME },
        { withCredentials: true }
      );
      setNICKNAME('');
      setTITLE('');
      setTEXT('');
      setCATEGORY('');
      setPHOTO_NAME('');
      setMessage('投稿が成功しました');
    } catch (error) {
      console.error('投稿エラー:', error);
      setMessage('投稿に失敗しました');
    }
  };

  return (
    <PostForm
      NICKNAME={NICKNAME}
      setNICKNAME={setNICKNAME}
      TITLE={TITLE}
      setTITLE={setTITLE}
      TEXT={TEXT}
      setTEXT={setTEXT}
      CATEGORY={CATEGORY}
      setCATEGORY={setCATEGORY}
      PHOTO_NAME={PHOTO_NAME}
      setPHOTO_NAME={setPHOTO_NAME}
      addThread={addThread}
      message={message} 
    />
  );
};

export default PostThread;