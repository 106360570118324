import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import SearchBar from './SearchBar';
import CategoryFilter from './CategoryFilter';
import CardList from './CardList';
import SortOptions from './SortOptions';
import styled from 'styled-components';
import { Guide, Post } from '../styles/icons';
import '../App.css';

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;

  @media (max-width: 768px) {
    max-width: 100%;
    padding: 0;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  background-color: var(--card-background);
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`;

const HeaderIconWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 20px;
  width: 33.33%;
`;

const HeaderIcon = styled.img`
  width: 80px;
  height: 80px;
  margin-right: 10px;

  @media (max-width: 768px) {
    width: 80px;
    height: 80px;
  }
`;

const TextButtonWrapper = styled.div`
  width: 66.66%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const HeaderText = styled.p`
  margin: 0;
`;

const NewPostButton = styled.button`
  display: flex;
  align-items: center;
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  font-size: var(--body-text);
  transition: background-color 0.3s;
  margin-top: 10px;

  &:hover {
    background-color: var(--hover-color-primary);
  }

  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
  }
`;

const NewPostIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

const ThreadListWrapper = styled.div`
  padding: 0;
  background-color: var(--background-color);
  width: 100%;
`;

const ThreadCardWrapper = styled.div`
  width: 100%;
  padding: 8px 0;

  @media (max-width: 768px) {
    margin: 0;
    padding: 8px 0;
  }
`;

const PaginationInfo = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  button {
    margin: 0 5px;
    padding: 5px 10px;
    border: 1px solid var(--border-color);
    background-color: white;
    color: var(--primary-color);
    cursor: pointer;
    border-radius: 3px;
    transition: background-color 0.3s, color 0.3s;

    &.active {
      background-color: var(--primary-color);
      color: white;
      border-color: var(--primary-color);
    }

    &:hover {
      background-color: var(--hover-color-primary);
    }
  }
`;

const BrowseThreads = () => {
  const [threads, setThreads] = useState([]);
  const [query, setQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('すべて'); // 初期値は「すべて」
  const [selectedSort, setSelectedSort] = useState('newest');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filteredThreads, setFilteredThreads] = useState(0); // カテゴリごとの投稿数
  const navigate = useNavigate();

  // スレッド取得関数
  const fetchThreads = useCallback(async (page = 1) => {
    try {
      const params = { query, sortBy: selectedSort, page, limit: 50 };
      if (selectedCategory !== 'すべて') {
        params.category = selectedCategory; // 「すべて」以外が選択されている場合にカテゴリを指定
      }
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/threads`, { 
        params,
        withCredentials: true
      });
      const { threads, totalPages, filteredCount } = response.data;
      setThreads(threads || []);
      setTotalPages(totalPages || 1);
      setFilteredThreads(filteredCount || 0); // 絞り込まれたカテゴリの投稿数をセット
      setCurrentPage(page);
    } catch (error) {
      console.error('スレッドの取得に失敗しました:', error);
      setThreads([]);
      setTotalPages(1);
      setFilteredThreads(0);
    }
  }, [query, selectedCategory, selectedSort]);

  // 初期ロード時およびカテゴリやソートが変更されたときにスレッドを取得
  useEffect(() => {
    fetchThreads();
  }, [fetchThreads]);

  // 新規投稿ボタンを押したときのハンドラ
  const navigateToNewPost = () => {
    navigate('/new-post');
  };

  // ページ変更時のハンドラ
  const handlePageChange = (page) => {
    fetchThreads(page);
  };

  const startCount = (currentPage - 1) * 50 + 1;
  const endCount = Math.min(currentPage * 50, filteredThreads);

  return (
    <Container>
      <Header>
        <HeaderIconWrapper>
          <HeaderIcon src={Guide} alt="Guide Icon" />
        </HeaderIconWrapper>
        <TextButtonWrapper>
          <HeaderText>被ってもOK！悩みや相談などを匿名で質問してみよう♪</HeaderText>
          <NewPostButton onClick={navigateToNewPost}>
            <NewPostIcon src={Post} alt="New Post Icon" />
            新規投稿する
          </NewPostButton>
        </TextButtonWrapper>
      </Header>
      
      <SearchBar query={query} setQuery={setQuery} handleSearch={() => fetchThreads(1)} />
      
      <CategoryFilter
        categories={['すべて', '介護制度', '介護の仕方', '病気の手当て', '介護疲れ', '仕事との両立', 'お金', '介護施設', '便利アイテム', 'その他']} // 「その他」を追加
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />
      
      <SortOptions selectedSort={selectedSort} setSelectedSort={setSelectedSort} />
      
      <ThreadListWrapper>
        {threads.length > 0 ? (
          threads.map(thread => (
            <ThreadCardWrapper key={thread.THREAD_ID}>
              <CardList thread={thread} />
            </ThreadCardWrapper>
          ))
        ) : (
          <p>スレッドが見つかりませんでした。</p>
        )}
      </ThreadListWrapper>
      
      <PaginationInfo>
        <p>{`${filteredThreads}件の相談を表示中 ${startCount}-${endCount}件目（全${filteredThreads}件）`}</p>
      </PaginationInfo>
      
      <Pagination>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            className={index + 1 === currentPage ? 'active' : ''}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </button>
        ))}
      </Pagination>
    </Container>
  );
};

export default BrowseThreads;