import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
`;

const Section = styled.section`
  margin-bottom: 20px;
`;

const Subtitle = styled.h2`
  font-size: 18px;
  margin-bottom: 10px;
  color: #666;
`;

const Paragraph = styled.p`
  font-size: 14px;
  line-height: 1.6;
  color: #444;
  margin-bottom: 10px;
`;

const List = styled.ul`
  padding-left: 20px;
  margin-bottom: 10px;
`;

const ListItem = styled.li`
  font-size: 14px;
  line-height: 1.6;
  color: #444;
  margin-bottom: 5px;
`;

const Guideline = () => {
  return (
    <Container>
      <Title>投稿ガイドライン</Title>
      
      <Section>
        <Subtitle>【匿名掲示板の目的】</Subtitle>
        <Paragraph>
          介護のお悩み相談所（以下「当サイト」といいます）の匿名掲示板は、コミュニティや記事を介してユーザーが自分の意見を述べたり、ユーザー同士で議論したりすることで見識を深めることを目的としています。トピックスやコメントは誰でも自由に投稿・閲覧することができますが、ルールや目的に沿わない投稿については削除される場合もあります。すべてのユーザーは他のユーザーに対して敬意を持ち、建設的な議論を行うことが求められます。利用目的をよく理解し、ルールを守ってご活用ください。
        </Paragraph>
      </Section>

      <Section>
        <Subtitle>【コメントシステム】</Subtitle>
        <Paragraph>
          書き込まれたコメントは当サイトの判断により、違法行為につながる投稿や公序良俗に反する投稿、差別や人権侵害などを助長する投稿については即座に排除されたり、表示を保留されたりすることがあります。また、いわゆる「荒らし」に相当すると判断された投稿についても削除される場合があります。なお、削除理由については当サイトは開示する義務を一切負いません。基本的に、一度投稿された内容は、ユーザーが削除することはできません。なお、コメントシステムの仕様や機能は、ユーザーに事前に通知することなく、裁量により変更されたり、中断または停止されることがあります。
        </Paragraph>
      </Section>

      <Section>
        <Subtitle>【投稿されたコメントの権利帰属】</Subtitle>
        <Paragraph>
          ユーザーが投稿したコメントに関する著作権は、投稿を行ったユーザーに帰属します。なお、コメントが投稿されたことをもって、ユーザーは当サイトに対して、投稿したコメントを当サイトが日本の国内外で無償かつ非独占的に利用する権利を期限の定めなく許諾（第三者へ許諾する権利を含みます）することに同意されたものとします。また、ユーザーは、当サイトおよび当サイトの指定する第三者に対し、投稿したコメントについて著作者人格権を行使しないことに同意されたものとします。
        </Paragraph>
      </Section>

      <Section>
        <Subtitle>【禁止されている投稿】</Subtitle>
        <List>
          <ListItem>法令に違反する投稿</ListItem>
          <ListItem>公序良俗に反する投稿</ListItem>
          <ListItem>犯罪または犯罪の助長にあたる投稿</ListItem>
          <ListItem>暴言、脅迫、犯罪予告</ListItem>
          <ListItem>個人情報の投稿（氏名、住所、電話番号、メールアドレスなど個人を特定可能な情報）</ListItem>
          <ListItem>他者のプライバシーを侵害する投稿</ListItem>
          <ListItem>誹謗中傷・差別に関する禁止事項</ListItem>
          <ListItem>営利目的の宣伝活動や政治活動に関する投稿</ListItem>
          <ListItem>不適切なサイトへの誘導やURL</ListItem>
          <ListItem>宗教、マルチ商法、ネットワークビジネス等の勧誘行為</ListItem>
          <ListItem>わいせつ、暴力的、グロテスクな画像や文書</ListItem>
          <ListItem>児童ポルノや幼児虐待にあたる画像や文書</ListItem>
          <ListItem>自殺、自傷行為、薬物乱用を誘発・助長する恐れがある投稿</ListItem>
          <ListItem>スパム・荒らし行為に関する禁止事項</ListItem>
          <ListItem>記事内容と関係のないスパム投稿</ListItem>
          <ListItem>一文字だけなど意味のないトピックの連続投稿</ListItem>
          <ListItem>荒らし行為や荒らしを煽る行為</ListItem>
          <ListItem>他人になりすました投稿</ListItem>
          <ListItem>一度削除された投稿の再投稿</ListItem>
          <ListItem>文章、画像、動画等の無断転載や著作権法違反</ListItem>
          <ListItem>引用の範囲を超えた他者コンテンツの無断転載、知的財産権侵害</ListItem>
          <ListItem>掲示板の雰囲気を悪くする行為</ListItem>
          <ListItem>その他、当サイトが不適切と判断した内容</ListItem>
        </List>
      </Section>

      <Section>
        <Subtitle>【本ガイドラインの変更】</Subtitle>
        <Paragraph>
          当サイトが必要と判断した場合には、ユーザーの承諾なしに本ガイドラインを変更することができるものとします。
        </Paragraph>
      </Section>

      <Section>
        <Subtitle>【免責事項】</Subtitle>
        <Paragraph>
          当サイトはユーザー間もしくはユーザーと第三者間とのトラブル、およびその他の損害について一切の責任を負いません。
        </Paragraph>
      </Section>

      <Paragraph>2024年9月1日制定</Paragraph>
    </Container>
  );
};

export default Guideline;
